import React from 'react'
import {Card, Row, Col} from 'react-bootstrap'



const Home2 = () => {

    const contentStyle = {
        height: '750px',
        color: 'white',
        background: '#181818',
        position: 'relative',
        left: '0px',
        top: '30%',
        position: 'relative',
    };

    return (
        <div styles = {contentStyle}>
            {}
        </div>
    )
}

export default Home2